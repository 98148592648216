.custom-button {
  min-width: 50px;
  letter-spacing: 0.5px;
  align-items: center;
  // line-height: 50px;
  padding: 13px;
  font-size: 15px;
  background-color: white;
  color: black;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    background-color: var(--paragraph);
    color: black;
    // border: 1px solid black;
  }

  &.isCta {
    background-color: rgb(211, 66, 66);
    color: white;

    &:hover {
      background-color: rgb(154, 23, 23);
      border: none;
    }
  }

  &.inverted {
    background-color: var(--paragraph);
    color: white;
    border: 1px solid black;

    &:hover {
      background-color: white;
      color: black;
      border: none;
      opacity: 0.85;
    }
  }
  &.disabled {
    background-color: grey;
    &:hover {
      background-color: grey;
    }
  }
}
@media screen and (max-width: 768px) {
  .custom-button {
    // width: 165px;
    padding: 15px;
  }
}
