.videoplayer-container {
  display: flex;
  flex-direction: column;
  .partners {
    display: inherit;
    flex-direction: row;
    padding: 15px 0;
  }

  > p {
    font-size: 1.2em;
  }
  @media (max-width: 500px) {
    .partners {
      flex-direction: column;
    }
  }
}

// wowza player hide or not
.wowza-player-hidden {
  display: none;
}
