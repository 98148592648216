.home {
  display: grid;
  grid-template-areas:
    "title"
    "video"
    "overview"
    "biographies";
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 1fr;
  column-gap: 20px;
  row-gap: 0px;
  justify-items: center;
}
.home-title {
  grid-area: title;
  font-family: "Tangerine", Cambria, Cochin, Georgia, Times, "Times New Roman",
    serif;
  font-size: 4.5rem;
  // text-shadow: 0 1px 10px #000000, 1px 0px 0px;
}
.home-video {
  grid-area: video;
}

.home-overview {
  grid-area: overview;
  max-width: 80%;
  margin-top: 20px;
}
.home-biographies {
  grid-area: biographies;
  max-width: 80%;
}

// .home img {
//   max-width: 100%;
// }
.home h3 {
  font-family: "Gotham", sans-serif;
  font-weight: 300;
  line-height: 2em;
}

.home h1 {
  text-align: center;
  font-weight: bold;
}

// .home h1,
// h3 {
//   max-width: 80%;
// }

.home {
  margin-top: 30px;
}

#myVideo {
  position: relative;
  // width: 900px;
  // height: 502px;
  width: 1100px;
  height: 630px;
}

@media screen and (max-width: 1366px) {
  #myVideo {
    width: 1000px;
    height: 560px;
  }
}

@media screen and (max-width: 1112px) {
  #myVideo {
    width: 1000px;
    height: 560px;
  }
  .home {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1024px) {
  #myVideo {
    width: 900px;
    height: 506px;
  }
}

@media screen and (max-width: 800px) {
  #myVideo {
    width: 720px;
    height: 403px;
  }
  .home {
    margin-top: 50px;
  }
}

@media screen and (max-width: 414px) {
  .home {
    margin-top: 60px;
  }
  .home-overview {
    max-width: 95%;
  }
  .home-biographies {
    max-width: 95%;
  }
  #myVideo {
    width: 392px;
    height: 210px;
  }
  .home-title {
    font-size: 2.5rem !important;
  }
}
@media screen and (max-width: 390px) {
  #myVideo {
    width: 380px;
    height: 204px;
  }
}

@media screen and (max-width: 375px) {
  #myVideo {
    width: 355px;
    height: 200px;
  }
}
@media screen and (max-width: 320px) {
  .home {
    margin-top: 30px;
  }
  #myVideo {
    width: 305px;
    height: 180px;
  }
}
