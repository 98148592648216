$sub-color: grey;

.chatbox {
  display: flex;
  flex-direction: column;
  .conversations {
    align-self: flex-start;
    height: 426px;
    // background-color: teal;
    overflow-y: scroll;
    width: 95%;
  }
  button {
    background-color: white;
  }
  .input {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: 95%;
    input {
      background: none;
      background-color: white;
      color: $sub-color;
      font-size: 18px;
      padding: 10px 10px 10px 5px;
      display: block;
      width: 100%;
      border: none;
      border-radius: 5px;
      border-bottom: 1px solid $sub-color;
      margin-right: 3px;
      &:focus {
        outline: none;
      }
    }
  }
}
