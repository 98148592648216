.sign-up {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 500px;
  .title {
    margin: 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .sign-up {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 480px;
  }
}

@media screen and (max-width: 320px) {
  .sign-up {
    height: 460px;
  }
}
