// h1 {
//   font-size: 5em;
// }

.audience-homepage {
  display: grid;
  grid-template-areas:
    "description description"
    "video video"
    "overview sidebar"
    "biographies sidebar";
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 2fr 1fr;
  grid-gap: 25px;
  justify-items: center;
}

.audience-description {
  // padding-top: 1rem;
  grid-area: description;
  text-align: center;

  .admin-buttons {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    button {
      margin-right: 20px;
      margin-top: 20px;
    }
  }
}
.audience-video {
  grid-area: video;
  display: grid;
  justify-content: center;
}
.audience-overview {
  grid-area: overview;
  margin-top: 20px;
  h1 {
    text-align: center;
  }
  h3 {
    font-family: "Gotham", sans-serif;
    font-weight: 300;
    line-height: 2em;
  }
}
.audicence-biographies {
  grid-area: biographies;
  max-width: 80%;
}

.audience-sidebar {
  margin-top: 25px;
  grid-area: sidebar;
  width: 100%;
}

.audience-popup {
  width: 600px;
  height: 380px;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  flex-direction: column;
  border: 0.5px solid var(--cardBG);
  box-shadow: 6px 6px 3px rgb(70, 69, 69);
  p:first-child {
    margin-top: 30px;
  }
  p {
    color: black;
    font-size: 1.2rem;
  }
  a {
    color: white;
  }
  button {
    background-color: var(--cardBG);
    color: white;
  }
  button:last-child {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.audience-popup-hidden {
  display: none;
}

.audience-biographies {
  h1 {
    text-align: center;
  }
}

// ipad pro/ipad potrait
@media screen and (max-width: 834px) {
  .audience-homepage {
    display: grid;
    grid-template-areas:
      "description"
      "video"
      "sidebar"
      "overview"
      "biographies";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
  }
  .audience-sidebar {
    margin-top: 0;
  }
  .audience-popup {
    width: 300px;
  }
}

@media screen and (max-width: 414px) {
  .audience-homepage {
    row-gap: 10px;
    column-gap: 20px;
  }
  .audience-overview,
  .audience-biographies {
    max-width: 95%;
  }
  // .audience-video {
  //   margin-top: 20px;
  // }
  .audience-description {
    margin-top: 30px;
  }
  h1 {
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 1366px) and (orientation: landscape) {
  .audience-sidebar {
    width: 380px;
  }
}

@media only screen and (max-width: 1112px) and (orientation: landscape) {
  .audience-sidebar {
    width: 300px;
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .audience-sidebar {
    width: 290px;
  }
}
@media only screen and (max-width: 800px) {
  .audience-sidebar {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
  .audience-description {
    margin-top: 0px;
  }
}
