@media (max-width: 500px) {
  .tabs {
    width: auto;
  }
}
.tabs__content {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 0px;
  color: var(--paragraph);
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  background: var(--cardBG);
  max-height: 550px;
}

.tabs__content h3 {
  color: var(--headline);
  font-weight: 700;
  font-family: inherit;
  font-size: 24px;
  margin-bottom: 10px;
}

.tabs__nav {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 10px 0 20px;
  background: var(--cardBG);
}

.tabs__button {
  background: rgba(0, 0, 0, 0);
  padding: 4px;
  color: var(--headline);
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  display: block;
  position: relative;
  font-family: inherit;
  font-weight: 400;
  padding-left: 20px;
}

.tabs__button:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 70%;
  height: 2px;
  margin-left: 20px;
  background: var(--activeColor);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 0.4s ease-in-out;
}

.tabs__button.active::after {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.4s ease-in-out;
}

.tabs__item:not(:last-child) {
  margin-right: 20px;
}
