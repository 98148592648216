* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --bg: #301e4e;
  --headline: #ffffff;
  --paragraph: #61221b;
  --cardBG: #463366;
  --tableHeader: #5f3b99;
  --cardText: #cabae2;
  --activeColor: #ff6e6c;
  --ff: "Montserrat", sans-serif;
}

@font-face {
  font-family: "Tangerine";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/tangerine/v12/Iurd6Y5j_oScZZow4VO5srNZi5FNym499g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Gotham";
  src: url(assets/Gotham-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: "Gotham";
  src: url(assets/Gotham-Bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: "Gotham Italic";
  src: url(assets/Gotham-XLightItalic.otf);
}

a {
  text-decoration: none;
}

h1 {
  color: var(--paragraph);
  font-family: "Gotham", sans-serif;
  font-weight: bold;
}

h2,
h3,
h4,
h5,
h6,
p {
  color: var(--paragraph);
}

body {
  // background-color: rgba(48, 30, 78, 1) !important;
  color: var(--paragraph) !important;
  font-family: "Gotham", sans-serif;
  font-weight: 300;
}
.bodyWrapper {
  position: relative;
}
.bodyWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // filter: brightness(40%);
  // background-image: url("./assets/bg.jpg");
  // background-repeat: no-repeat;

  // background-size: cover;
  background-color: #efc8c4;
}

.body {
  position: relative;
  height: 100vh !important;
}
/* for pages with tabs */
.admin-container {
  max-width: 1200px;
  margin: 80px auto;
}

.wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto 1fr;
  grid-template-columns: 12fr;
  grid-template-areas:
    "content1"
    "footer1";
  justify-items: center;
  align-items: center;
}

// content class becomes sub main grid
// to define layouts for homepage and register (same layout)
// while audience will have chatbox as additional layout
.content {
  margin-top: 8vh;
  grid-area: content1;
  max-width: 80%;
}

.centered {
  display: grid;
  justify-items: center;
}

// NAVIGATION
@media screen and (max-width: 768px) {
  nav {
    justify-content: space-around !important;
  }
}
nav {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  min-height: 75px;
  background-color: var(--cardBG);
  opacity: 0.95;
  z-index: 99;

  .nav-links {
    z-index: 99;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // width: 30%;
  }

  .nav-links li {
    list-style: none;
  }

  .nav-links li a {
    margin-left: 20px;
    padding: 20px;
    font-size: 1.4em;
    font-weight: 700;
  }
  .nav-links li a:hover {
    background-color: #463366;
    color: var(--paragraph);
  }
  .burger {
    display: none;
  }

  .burger div {
    width: 25px;
    height: 3px;
    background-color: var(--paragraph);
    margin: 5px;
  }

  .burger:hover {
    cursor: pointer;
  }

  .nav-active {
    transform: translateX(0%) !important;
  }

  .header-logo img {
    padding-top: 3px;
    width: 130px;
  }

  @media screen and (max-width: 1112px) {
    .header-logo img {
      width: 120px;
    }
  }

  @media screen and (max-width: 768px) {
    .header-logo img {
      width: 110px;
    }

    .nav-links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      right: 0;
      height: 92vh;
      top: 10vh;
      background-color: var(--cardBG);
      transform: translateX(120%);
      transition: transform 0.5 ease-in;
      width: 80%;
    }

    .nav-links li a {
      margin-left: 0;
    }

    .nav-links li {
      margin: 20px 0;
    }
    .nav-links li:last-child {
      align-self: center;
    }

    .burger {
      display: block;
    }
  }
}

.footer {
  // padding-top: 80px;
  display: grid;
  grid-area: footer1;
  text-align: center;
  ul {
    display: flex;
    justify-content: space-evenly;
  }
  li {
    list-style: none;
    font-size: 2em;
    img:last-child {
      margin-left: 2rem;
      width: 160px;
    }
    img:first-child {
      width: 120px;
    }
  }
  ul,
  p {
    padding: 20px;
  }
  ul {
    li {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    max-width: 95%;
  }
}

@media only screen and (min-device-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .content {
    max-width: 1000px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-width: 1112px) and (orientation: landscape) {
  .content {
    max-width: 1080px;
  }
}

@media only screen and (min-device-width: 1112px) and (max-width: 1366px) and (orientation: landscape) {
  .content {
    max-width: 90%;
    margin-top: 8vh;
  }
}

@media screen and (max-width: 1920px) {
  #endSession {
    margin-left: 1150px;
  }
}

@media screen and (max-width: 1366px) {
  #endSession {
    margin-left: 750px;
  }
}

@media screen and (max-width: 1112px) {
  #endSession {
    margin-left: 730px;
  }
}
@media screen and (max-width: 1024px) {
  #endSession {
    margin-left: 650px;
  }
  h1 {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 800px) {
  // .bodyWrapper::before {
  //   background-image: url("./assets/bg-landscape.png");
  //   background-repeat: no-repeat;
  //   background-size: cover;
  // }
}

@media screen and (max-width: 800px) {
  #endSession {
    margin-left: 400px;
  }
}
@media screen and (max-width: 768px) {
  #endSession {
    margin-left: 500px;
  }
  .footer {
    ul {
      li {
        img:last-child {
          margin-left: 2rem;
          width: 110px;
        }
        img:first-child {
          width: 80px;
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  #endSession {
    margin-left: 0px;
  }
  .content {
    margin-top: 30px;
  }
  .burger {
    padding-left: 130px;
  }
}

@media screen and (max-width: 320px) {
  .content {
    margin-top: 60px;
  }
}
