.message p {
  max-width: 100%;
  padding-bottom: 10px;
  font-size: 0.9em;
}
.message span:first-child {
  margin-right: 10px;
  font-weight: 600;
  font-size: 1em;
}
.message span + span {
  font-size: 0.8em;
}
.message:hover {
  background-color: #503b75;
}
