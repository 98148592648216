.bio-wrapper {
  position: relative;
  margin: 20px 0 0 0;
}
.bio-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #463366;
  opacity: 0.7;
}
.bio {
  position: relative;
  display: grid;
  grid-template-areas: "profile-pic profile-desc";
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  padding-bottom: 40px;
}

.profile-pic {
  grid-area: profile-pic;
  // height: 430px;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  // line-height: 0.5;
  padding: 20px 0 0 20px;
  h3 {
    margin-top: 7px;
    margin-bottom: 0px;
    // font-weight: 900;
  }
  span {
    font-family: "Gotham Italic", "Gotham";
    font-size: 0.9em;
  }
  img {
    width: 300px;
  }
}

.profile-desc {
  padding: 20px 20px 0 0;
  grid-area: profile-desc;
  p {
    font-size: 1.1em;
  }
  p:last-child {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .bio-wrapper {
    padding: 5px;
    margin: auto auto 20px auto;
  }
  .profile-pic img {
    width: 250px;
  }
  .profile-desc {
    padding: 30px 15px 15px 15px;
  }
}

@media screen and (max-width: 414px) {
  .bio {
    display: block;
    grid-template-areas: "profile-pic" "profile-desc";
    grid-template-rows: 1fr auto;
    justify-content: center;
    justify-items: center;
    grid-gap: 0px;
  }
  .profile-pic {
    text-align: center;
    padding: 15px;
    // height: 420px;
    img {
      width: 290px;
      align-self: center;
    }
  }
  .profile-desc {
    padding: 15px;
  }
}

@media screen and (max-width: 375px) {
  .profile-pic {
    img {
      width: 270px;
    }
  }
}

@media screen and (max-width: 320px) {
  .profile-pic {
    padding: 15px;
    // height: 360px;
    img {
      width: 230px;
    }
  }
}

@media screen and (max-width: 768px) {
  .profile-desc {
    padding: 25px 15px 15px 15px;
  }
}
